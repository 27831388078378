@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: Avenir;
    src: url(./../public/fonts/Avenir-Regular.ttf);
  }

  html {
    font-family: 'Avenir', 'Work Sans', sans-serif;
    overflow: auto !important;
  }

}

:root,
[data-theme] {
  background-color: white !important;
}

[contenteditable]:empty::before {
  font-style: normal !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-background-clip: text;
    -webkit-text-fill-color: black;
    box-shadow: inset 0 0 20px 20px #FCF3FF;
}

/* width */
::-webkit-scrollbar {
    width: 4px;
    height: 4px;

  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 0px grey;
    border-radius: 10px;

  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #f4d6ff;
    border-radius: 15px;
    height: 100px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #dd66ff;
  }

  /* .outer-container {
    scrollbar-width: thin;
    scrollbar-color: #dd66ff;;
  }

  /* For Webkit (Chrome, Safari, Edge) */
  /* .outer-container::-webkit-scrollbar {
    width: 2px;
  } */

  /* .outer-container::-webkit-scrollbar-track {
    background: #dd66ff;;
  } */

  /* .outer-container::-webkit-scrollbar-thumb {
    background-color: #f4d6ff;
    border-radius: 4px;
  }  */

#sidebar {
  -webkit-transition: all 300ms cubic-bezier(0, 0.77, 0.58, 1);
  transition: all 300ms cubic-bezier(0, 0.77, 0.58, 1);
}

#sidebar.show {
  transform: translateX(0);
}


.toolbarClassName .rdw-link-modal{
  height: 230px !important;
}

/* radio button */

label.custom-radio-button input[type='radio'] {
  opacity: 0;
}

label.custom-radio-button input[type='radio'] ~ .helping-el {
  background-color: #ffffff;
  border: 2px solid #C7CBD4;
  border-radius: 50%;
  display: inline-block;
  margin-right: 7px;
  padding: 8px;
  position: relative;
  top: 3px;
}

label.custom-radio-button input[type='radio']:checked ~ .helping-el {
  border: 2px solid #A033FF;
}

label.custom-radio-button input[type='radio']:checked ~ .helping-el:after {
  background-color: #A033FF;
  border-radius: 50%;
  content: ' ';
  font-size: 20px;
  height: 12px;
  left: 2px;
  position: absolute;
  top: 2px;
  width: 12px;
}

.placeholderPadding::placeholder {
  padding: 10px 0px 10px 0px;
}


/* ------------------- RESUME MODAL DIALOG STYLES -------------------------*/
.resume-modal .modal-header{
  background: linear-gradient(246.08deg, rgba(126, 107, 255, 0.2) -5.7%, rgba(70, 191, 255, 0.2) 96.99%);
}

.modal-stepper{
  top: -5px;
  position: relative;
}

.resume-modal-close{
  position: fixed;
  right: 10px;
  /* margin-top: -25px; */
  z-index: 999;
  font-size: 18pt;
  color: #666666;
  top: 2px;
  clear: both;
  cursor: pointer;
}
/* ------------------- RESUME MODAL DIALOG END    -------------------------*/


.react-datepicker__month-dropdown-container .react-datepicker__month-dropdown-container--select{
  border:  1px border black;
}

/* .hjYtIJ {
  height: 200px !important;
  border: 1px dashed var(--neutral-grey-4, #C7CBD4) !important;
  border-radius: 8px !important;
}

.bXgVCj span {
  margin-bottom: 15px;
}

.dmajdp > span {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    color: #A033FF !important;
    font-size: 12px !important;
    font-weight: 900 !important;
}

.dmajdp .file-types {
  display: none;
} */

.react-datepicker__input-container input {
  width: 100%;
  border:  1px  solid rgb(199 203 212);
  height: 2.5rem;
  border-radius: 0.5rem;
  padding: 0px 10px;
}

.react-datepicker__input-container input:focus {
  outline: none;
  border-color: #4f46e5;
}

.react-datepicker__month-container .react-datepicker__day--selected, .react-datepicker__month-container .react-datepicker__day--keyboard-selected  {
  background:rgb(160 51 255);
}


.react-datepicker__month-container  .react-datepicker__month-select {
  border-radius:  2px;
  margin: 0.5rem 0rem;
}

.react-datepicker__month-container .react-datepicker__year-select{
  border-radius:  2px;
  margin: 0.5rem 0rem;
}

.react-datepicker__month-container  .react-datepicker__header {
  background:rgb(160 51 255);
}

.react-datepicker__month-container{
  min-width: 410px;
}


grammarly-editor-plugin{
  width: 100%;
  --grammarly-button-z-index: 0px;
}


/* Custom Option Styles */
.list-select-radio-option {
  display: flex;
  align-items: center;
  padding: 10px 16px;
  cursor: pointer;
}

.list-select-radio-option.selected {
  background-color:  #fcf3ff;
  color: black;
}

 .list-select-radio-option.focused {
  background-color: #f0f0f0;
} 

.list-select-radio-option input[type="radio"] {
  margin-right: 8px;
  background-color: #f0f0f0;
  color: red;

}

/* Custom Label Styles */
.list-select-radio-option label {
  font-size: 14px;
}

/* Additional styles for hovering the custom option */
.list-select-radio-option:hover {
  background-color: #fcf3ff;
  color: black;
}


.list-select-radio-option input[type="radio"] {
  margin-right: 8px;
  appearance: none;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 1px solid #8F98A9;
  position: relative;
  cursor: pointer;
}

.list-select-radio-option input[type="radio"]:checked {
  border: 1px solid #A033FF;
}

.list-select-radio-option input[type="radio"]:checked::before {
  content: '';
  position: absolute;
  width: 8px;
  height: 8px;
  top: 50%;
  left: 50%;
  background-color: #A033FF;
  border-radius: 50%;
  transform: translate(-50%, -50%);
}

.list-select-radio-option:not(:hover) {
  background-color: white; 
  color: black;
} 


 /* .list-select-radio-option.selecetd:not(:hover) {
  background-color: #A033FF; 
  color: black;
}  */

.list-select-radio__menu {

  max-height: 180px; /* Adjust the max height as needed */
  overflow-y: auto; /* Enable vertical scrolling when the list exceeds max height */

}
.list-select-radio__menu-list {
  max-height: 180px !important; /* Adjust the max height as needed */
  overflow-y: auto; /* Enable vertical scrolling when the list exceeds max height */
 

}




