.custom-radio {
    cursor: pointer;
    width: 20px;
    height: 20px;
    border: 2px solid #C7CBD4;
    border-radius: 50%;
    display: inline-block;
    position: relative;
  }
  
  /* for inner filled circle */
  .custom-radio::after {
    content: '';
    width: 12px;
    height: 12px;
    background: #A033FF;
    position: absolute;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: opacity 0.2s;
  }
  
  .radio-label {
    display: inline-block;
    vertical-align: middle;
  }
  
  .radio-input {
    visibility: hidden;
  }
  
  .radio-input:checked + .custom-radio {
    border: 2px solid #A033FF;
  }
  
  .radio-input:checked + .custom-radio::after {
    opacity: 1;
  }
  