.cambri-contenteditable {
    border: 1px solid rgb(199 203 212 ) ;
    padding: 0.5rem;
    font-size: 100%;
    line-height: 1.15;
    color: rgb(31,41,55);
    background-color:  rgb(254 254 254);
    border-width: 1px;
    border-radius: 0.5rem;
    font-family: 'Avenir','Work Sans', sans-serif;
    overflow-y: auto;
    word-wrap: break-word;
    white-space: break-spaces;
    width: 100%;
    height: 100%;
  }

  [contenteditable]:empty::before {
    content: attr(placeholder);
    color: #999;
    font-style: italic;
  }

  .insideInput{
    pointer-events: none;
    cursor: pointer;
    display: inline-block; 
    background-color: #FCF3FF ; 
    color: #A033FF; 
    padding: 0.2rem 1.5rem; 
    margin: 0 0.2rem;   
    border: 1px solid #E3E5E9
  }